export const attractionsMixin = {
    data() {
        return {
            loading: false,
            regions: [],
            form: {
                title: null,
                image: null,
                image_delete: null,
                description: null,
            },
        };
    },
    methods: {
        clearForm() {
            for (const key in this.form) {
                this.form[key] = null;
            }
        },
        async fetchAttraction() {
            this.loading = true;

            try {
                const { data } = await this.axios.get(`/attractions/${this.$route.params.id}`);

                // заполняем данные
                for (const [key, val] of Object.entries(data.data)) {
                    this.form[key] = val;
                }

                this.loading = false;
            } catch (ex) {
                console.log('cant fetch attraction' + ex);
                this.loading = false;
                this.$router.push('/error-page');
            }
        },
    },
};
